.spinner {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: #4ea3bb;
}

.spinner > div {
    color: #000000;
}
