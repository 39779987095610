.banner {
    padding-top: 200px;
    border: white 1px solid;
    margin: auto;
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    max-width: 1500px;
    background: url("../../assets/banner.jpg") no-repeat center center #d5b76b;
    background-size: contain;
}

.mobile-banner {
    padding-top: 100px;
    border: white 1px solid;
    margin: auto;
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    max-width: 1500px;
    background: url("../../assets/banner.jpg") no-repeat center center #d5b76b;
    background-size: contain;
}
