@import "~react-image-gallery/styles/css/image-gallery.css";

.MuiContainer-root.product-view {
    height: fit-content;
    display: block;
}

.product-view .image-wrapper img {
    width: 100%;
}

.image-gallery-thumbnails {
    margin-top: 0 !important;
}

@media (min-width: 960px) {
    .product-view .text {
        margin-top: 90px;
        color: #000000;
    }

    .image-gallery-slide-wrapper {
        margin-top: 50px !important;
    }
}

@media (max-width: 959px) {
    .product-view .text {
        margin-top: 10px;
        color: #000000;
    }

    .image-gallery-slide-wrapper {
        margin-top: 20px !important;
    }
}

.product-view .text h2 {
    font-size: 30px;
    font-weight: bold;
    margin-top: 3%;
}

.product-view .text span h1 {
    font-size: 20px;
    font-weight: 300;
    margin-top: 3%;
}

.product-view .text h3 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 300;
}

.product-view .text .quantity {
    margin-bottom: 5px;
}

.right-align {
    display: flex;
    justify-content: flex-end;
}

.center {
    display: flex;
    justify-content: center;
}

.MuiContainer-root.product-view .custom-button {
    color: #000;
    background-color: #c1ffbd;
    margin-top: 10%;
    margin-bottom: 10%;
    border: 2px solid black;
}

.MuiContainer-root.product-view .custom-button-disabled {
    background-color: lightgray;
    margin-top: 10%;
    margin-bottom: 10%;
    border: 2px solid black;
}

.MuiContainer-root.product-view .custom-button:hover {
    color: #c9d1d9;
}

.MuiContainer-root.product-view .custom-button svg {
    margin-right: 10px;
}

.MuiContainer-root.product-view .increase-product-quantity {
    background-color: #03dac5;
    border-color: #03dac5;
}

.product-view .MuiGrid-root .MuiGrid-item {
    padding: 5px 15px;
}

@media (min-width: 960px) {
    .MuiContainer-root.product-view {
        margin: 100px 0 0;
    }
}

@media (max-width: 959px) {
    .MuiContainer-root.product-view {
        margin: 100px 0 0 0;
    }
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
    border: 4px solid #c1ffbd !important;
}

.notes-section {
    padding-left: 20px;
    padding-right: 20px;
}

.measurements-section {
    padding-left: 20px;
    padding-right: 20px;
}

@media (min-width: 960px) {
    .measurements-image {
        width: 60% !important;
    }
}

@media (max-width: 959px) {
    .measurements-image {
        width: 100% !important;
    }
}

.image-gallery {
    border: black 3px solid;
    border-radius: 10px;
}

.backdrop {
    background: white;
    border: 5px white solid;
    border-radius: 10px;
    padding: 15px;
}

.split-soldout {
    white-space: pre-line;
    text-align: center;
}
